import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Drawer } from 'antd';
import React, { useState } from 'react';






// Modal  弹窗封装
export function reactModal(param) {
  /**
   * 
   * @param {boolean} open   弹窗modal 是否显示 
   * @param {string} type   弹窗类型
   * @param {string} title   弹窗title文字
   * @param {string} okText   弹窗 确认按钮文字
   * @param {string} cancelText   弹窗 取消按钮文字
   * @param {string / node} content   弹窗 中间文字内容
   * @param {number} width   弹窗 宽度
   * @param {boolean} footer   弹窗底部文字是否显示 (默认显示,null不显示)
   * @param {function} onOk   弹窗 确定回调
   * @param {function} onCancel   弹窗取消回调
   * @param {function} footer   弹窗页脚
   * @param {string} maskClosable   false = 点击蒙版不关闭弹窗 ,默认true可关闭
   * @param {boolean} closable   是否显示右上角的关闭按钮 ,默认true
   * @param {function} modalRender   自定义渲染对话框
   */

  //!  示例  
  // React.reactModalClick({
  //   open:xxx,
  //   type:'xxx',
  //   title:'xxx',
  //   okText:"xxx",
  //   content:xxx,
  //   cancelText:"xxx",
  //   onOk:xxx,
  //   onCancel:xxx,
  //   maskClosable:xxx,
  //   closable:xxx,
  // })



  const { open, type, } = param


  if (open) {
    return (
      <>
        <Modal {...param} >
          {param.content}
        </Modal>
      </>
    )
  } else if (type === "info") {
    Modal.info({ ...param });
  } else if (type === "success") {
    Modal.success({ ...param });
  } else if (type === "error") {
    Modal.error({ ...param });
  } else if (type === "warning") {
    Modal.warning({ ...param });
  } else if (type === 'confirm') {
    Modal.confirm({ ...param });
  }

}



export function reactDrawer (param) {

  /**
   * @param {string} title  抽屉标题
   * @param {string} placement  抽屉方向， (right , left , top , bottom)
   * @param {number} width   抽屉宽度
   * @param {number} height   抽屉高度
   * @param {boolean} open  抽屉是否显示
   * @param {node} content  抽屉中间文字内容
   *  抽屉 确定按钮和取消按钮是否显示(false不显示 ， true显示 , 默认false)
   * @param {boolean} extra  
   * @param {string} okText   抽屉 确认按钮文字
   * @param {string} cancelText   抽屉 取消按钮文字
   * @param {strin} onClose  抽屉 关闭回调
   * @param {strin} onOk   抽屉 确定回调
   * @param {strin} maskClosable   false = 点击蒙版不关闭弹窗 ,默认true可关闭
   */


  // 示例
  // React.drawerShow({
  //   title:'xxx',
  //   placement:'right',
  //   width:600,
  //   height:600,
  //   extra:false,
  //   open:true,
  //   content:modalView
  //   onClose:(e) => xxx(false)
  //   maskClosable:false,
  // extra: (
  //   <>
  //     <Space>
  //       <Button type="danter" onClick={() => { setCheckMaterial(false) }}>关闭弹窗</Button>
  //     </Space>
  //   </>
  // )
  // })}


  // const {
  //   title,
  //   placement,
  //   width,
  //   height,
  //   open,
  //   content,
  //   onClose,
  //   onOk,
  //   okText,
  //   cancelText,
  //   extra,
  //   maskClosable,
  // } = param



  return <Drawer
    {...param}
  >
    {param.content}
  </Drawer>
}
