import { Col, ConfigProvider, Row, Spin } from "antd";
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import { reactDrawer, reactModal } from '@/components/modal/modal'; //弹窗
import { pageType } from '@/utils/common'; //公共方法组件
import request from "@/utils/request"; //全局加载方法
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import './common.less'
// 当从节点模块导入Swiper自定义元素时，我们需要手动注册它。它应该只执行一次，并且它会全局注册 Swiper 自定义元素。
import { register } from 'swiper/element/bundle';
import './app.less';

dayjs.locale('zh-cn');

/* // @ts-ignore  忽略错误 */
// 全局loding
const loading = (
  <Row justify="center" align="middle" style={{ height: "100vh", background: '#00000020' }}>
    <Col>
      <Spin size="large"></Spin>
    </Col>
  </Row>
);


const Home = React.lazy(() => import("@/controller/home/home"));
const Jobs = React.lazy(() => import("@/controller/jobs/jobs"));  /* 人才招聘 */
const About = React.lazy(() => import("@/controller/about/about")); /* 关于我们 */
const NewsInside = React.lazy(() => import("@/controller/newsInside/newsInside")); /* 新闻内页 */



// 页面rem适配
const changeSize = () => {
  // 如果是手机类型，直接return
  if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
    var stdw = 750;
    // var stdh = 1334;
    const baseSize = 100

    var docEl = document.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        const scale = clientWidth / stdw
        // docEl.style.fontSize = 100 * scale + 'px';
        let size = (baseSize * Math.min(scale, 2)) < 38 ? 38 : (baseSize * Math.min(scale, 2))
        docEl.style.fontSize = size + 'px';

      };
    if (!document.addEventListener) return;
    recalc();
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener('DOMContentLoaded', recalc, false);
    return
  }


  // 设置1rem 等于多少 px
  const baseSize = 100
  // 当前页面宽度和高度相对于 1920x1080的缩放比例，取两者中较小的一个作为缩放比例。
  const scale = document.documentElement.clientWidth / 2560
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  // let size = (baseSize * Math.min(scale, 2)) < 64 ? 64 : (baseSize * Math.min(scale, 2))
  let size = (baseSize * Math.min(scale, 2)) < 34 ? 34 : (baseSize * Math.min(scale, 2))
  // let size = baseSize * Math.min(scale, 2)
  document.documentElement.style.fontSize = size + 'px'
}



// !挂载全局方法
(React as any).$axios = request; //全局axios 方法
(React as any).pageType = pageType(); //当前页面类型 pc addroid ios
(React as any).reactModal = reactModal; //全局请求地址
(React as any).reactDrawer = reactDrawer; //全局请求地址



function App() {

  useEffect(() => {

    changeSize()  //页面rem适配
    register();  //swiper 加载的时候需要

    return () => {
      //! 组件卸载时将其删除
      (React as any).$axios = undefined;
      (React as any).pageType = undefined;
      (React as any).reactModal = undefined;
      (React as any).reactDrawer = undefined;
    };

  }, []);


  window.onresize = () => changeSize()  //页面改变的时候调整适配

  return (
    <ConfigProvider locale={zhCN}>

      <HashRouter>  {/* 浏览器的路由模式*/}
        <React.Suspense fallback={loading}>   {/* 因为是懒加载，加载过程中的loding */}
          <Routes>
            <Route path="/*" element={<Home />}></Route>  {/* 首页 */}
            <Route path="/jobs" element={<Jobs />}></Route>   {/* 人才招聘 */}
            <Route path="/about" element={<About />}></Route>     {/* 关于我们 */}
            <Route path="/newsInside" element={<NewsInside />}></Route>     {/* 新闻内页 */}
          </Routes>
        </React.Suspense>
      </HashRouter>
    </ConfigProvider>

  );
}

export default App;
