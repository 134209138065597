// 封装axios
import React from 'react'
import axios from 'axios'
import { message } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';

const devBaseUrl = 'http://172.16.3.204:9020'  //开发环境
// const devBaseUrl = 'https://124.221.104.20'  //开发环境
// const devBaseUrl = 'https://sa.giraffar.com/api/'  //开发环境
// const devBaseUrl = 'http://124.222.138.92:9020'  //测试服务器
// const proBaseUrl = 'https://sa.giraffar.com/api/'  //生产环境
const proBaseUrl = window.location.origin + '/api/'  //生产环境

const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseUrl : proBaseUrl   //  请求域名
const TIMEOUT = 50000   //请求时间(毫秒单位)
// process.env.NODE_ENV === 'development'   判断是否是开发环境

export { BASE_URL }




// 创建一个axios实例
const instance = axios.create({
    baseURL: BASE_URL, //请求域名
    timeout: TIMEOUT //请求超时时间 
})
//设置axios拦截器: 请求拦截器
instance.interceptors.request.use(config => {
    // config.data = JSON.stringify(config.data);
    let headers = cloneDeep(config.headers);


    if (headers['Content-Type']) {
        // 自定义配置头部

        // headers.Authorization = process.env.NODE_ENV === 'development' ? localStorage.getItem('token') : sessionStorage.getItem('token')
        // headers.Authorization = sessionStorage.getItem('token')  //配置请求头token 

    } else {
        // 自动配置默认头部
        headers = {
            'Content-Type': 'application/json',
            // 'Authorization': process.env.NODE_ENV === 'development' ? localStorage.getItem('token') : sessionStorage.getItem('token')
        }

    }
    //得到请求方式和请求体数据
    const token = sessionStorage.getItem('token')
    if (token) {
        // config.headers.Authorization = token
        config.headers = {
            'game-token': token
        }
    }
    return config;
}, err => {
    // message.error('网络连接异常，请检查网络')
    // 请求未成功发出，如：没有网络...
    return Promise.reject(err)
})

// 登录过期提示
const tips = () => {
    // process.env.NODE_ENV === 'development' ? localStorage.removeItem('token') : sessionStorage.removeItem('token')
    // message.error('登录过期,请重新登录~')
    // return
}

//设置axios拦截器: 响应拦截器
instance.interceptors.response.use(res => {
    message.config({
        top: '30vh',
    })
    // 成功响应的拦截
    const data = res.data;
    const code = data.code;
    // 处理请求不成功
    // switch (code) {
    //     case 1002:
    //         // message.error('1自然日内发送短信条数不超过5条')
    //         message.error('当前手机号不存在账号，请到游戏内注册后再登录哦~')
    //         break;
    //     // default:
    //     //     return data
    // }
    if (code == 1002) return data   //没有账号
    if (code == 4001) return data  //提示登录失败
    if (code !== 200) message.error(data.message)
    return data;
}, err => {
    //请求的地址错误 会进入这里
    // 也可以在这里处理请求状态嘛 ，比如503,404 err.response.status
    //   return err
    return Promise.reject(err)
})


export default instance
