// 通用方法
// import $ from 'jquery'


//! 返回顶部
// export function top() {
//   $("html,body ").animate({
//     scrollTop: 0
//   }, 500, function () {
//   });
// }
export function top(duration = 500) {
  const scrollStep = -window.scrollY / (duration / 15);
  let scrollInterval;

  function step() {
    if (window.scrollY === 0) {
      clearInterval(scrollInterval);
    } else {
      window.scrollBy(0, scrollStep);
    }
  }

  scrollInterval = setInterval(step, 15);
}


// 用来截取地址参数的
export function searchUrl(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  return r
}


// 当前是什么端，什么类型
export function pageType() {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) return 'android'
  if (isiOS) return 'ios'
  return 'pc'
}


export function getQueryVariable(url, variable) {
  var query = url?.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
  if (!query) return
  // ['https://qq.com','appid=1234567890&name=Tom']
  var vars = query?.split("&"); // 根据“&”切割成数组
  // ['appid=1234567890','name=Tom']
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i]?.split("=");
    // ['appid','1234567890']
    if (pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
  }
  return ;
}
